<template>
  <div class="qgxq">
    <img src="../assets/qgxq/banner.png" class="banner" />
    <div class="bannerSj"></div>
    <div class="rows">
      <div class="row">
        <div class="colorBlock"></div>
        <div class="fonts">—200+校区</div>
      </div>
      <div class="row">
        <div class="colorBlock c1"></div>
        <div class="fonts">—100+城市</div>
      </div>
      <div class="row">
        <div class="colorBlock"></div>
        <div class="fonts">—10万+青少年</div>
      </div>
    </div>
    <div class="mapArea">
      <img src="../assets/qgxq/map.png" class="map" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'qgxq',
  components: {}
};
</script>
<style lang="less">
.qgxq {
  background: #f7f7f7;
  .banner {
    width: 100%;
    display: block;
  }
  .bannerSj {
    width: 0;
    height: 0;
    border-left: 85/19.2vw solid transparent;
    border-right: 85/19.2vw solid transparent;
    border-top: 85/19.2vw solid #007dd3;
    margin: 0 auto;
  }
  .mapArea {
    width: 100%;
    padding-bottom: 240/19.2vw;
    img {
      width: 100%;
      display: block;
    }
  }
  .rows {
    padding-left: 340/19.2vw;
  }
  .row {
    display: flex;
    align-items: center;
    margin-top: 36/19.2vw;
    .colorBlock {
      width: 24/19.2vw;
      height: 24/19.2vw;
      background: #007ed1;
      margin-right: 29/19.2vw;
      &.c1 {
        background: #f2902f;
      }
    }
    .fonts {
      font-size: 30/19.2vw;
      font-family: 'Microsoft YaHei';
      color: rgb(54, 54, 54);
      line-height: 1.2;
    }
  }
}
</style>
